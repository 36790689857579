import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

export const SocketContext = React.createContext(null);

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    background: 'rgba(255,0,0,0.6)',
    color: 'white',
    textAlign: 'center',
    width: 250,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    zIndex: 99999,
  },
});

function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const { data } = useSelector(state => state.auth);
  const classes = useStyles();

  const loadSocket = () => {
    const accessToken = sessionStorage.getItem('FRIGOCENTER.ACCESS_TOKEN');

    const con = io(process.env.REACT_APP_SOCKET_URL, {
      reconnection: false,
      query: {
        token: accessToken,
      },
    });

    con.on('connect', () => {
      NotificationManager.success('Socket conectado.');
      console.log('SET SOCKET');
      setSocket(con);
    });
    con.on('disconnect', () => {
      NotificationManager.error('Socket desconectado.');
      if (socket) {
        setSocket(null);
      }
    });
    con.on('connect_error', err => {
      console.log('ERR1', err);
      // NotificationManager.error('Erro ao conectar ao socket');
      if (socket) {
        setSocket(null);
      }
    });
    con.on('error', err => {
      console.log('ERR', err);
      // NotificationManager.error('Erro ao conectar ao socket');
      if (socket) {
        setSocket(null);
      }
    });

    con.on('message', ({ type, message }) => {
      console.log('TYPE', type);
      const [returnCode, returnType] = type?.split(':');
      if (returnType === 'error') {
        NotificationManager.error(message);
      } else if (returnType === 'success') {
        // window.location.reload();
        NotificationManager.success(message);
      }
    });
  };

  useEffect(() => {
    if (data) {
      loadSocket();
    }
  }, [data]);

  return (
    <SocketContext.Provider value={{ socket, loadSocket }}>
      {children}
      {data && !socket && (
        <div className={classes.root}>
          <span>SOCKET NÃO CONECTADO</span>
        </div>
      )}
    </SocketContext.Provider>
  );
}

export default SocketProvider;
