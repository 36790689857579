import { NotificationManager } from 'react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Types as AuthTypes, Creators as AuthActions } from 'store/ducks/Auth';
import { parseError, setTokens, removeTokens } from 'util/index';
import Api from 'util/Api';

function* login({ payload }) {
  try {
    const { status, data } = yield call(Api.post, '/login', payload);
    if (status === 200) {
      yield call(
        setTokens,
        data?.token,
        data?.refreshToken,
        data?.roles?.toString()
      );
      yield put(AuthActions.loginSuccess({ data }));
    } else {
      removeTokens();
      parseError(data);
      yield put(AuthActions.loginError());
    }
  } catch (error) {
    removeTokens();
    parseError(error);
    yield put(AuthActions.loginError());
  }
}

function logout() {
  removeTokens();
}

function* watchLogin() {
  yield takeLatest(AuthTypes.LOGIN, login);
}

function* watchLogout() {
  yield takeLatest(AuthTypes.CLEAN_AUTH_USER, logout);
}

export default function* rootSaga() {
  yield all([fork(watchLogin), fork(watchLogout)]);
}
