import React, { useEffect, useState } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import URLSearchParams from 'url-search-params'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import 'assets/vendors/style'
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from 'constants/ThemeColors'
import RTL from 'util/RTL'
import { Creators as AuthActions } from 'store/ducks/Auth'
import { getToken, getTypeUser, getRefreshToken } from 'util/index'
import { NotificationContainer } from 'react-notifications'
import indigoTheme from './themes/indigoTheme'
import cyanTheme from './themes/cyanTheme'
import orangeTheme from './themes/orangeTheme'
import amberTheme from './themes/amberTheme'
import pinkTheme from './themes/pinkTheme'
import blueTheme from './themes/blueTheme'
import purpleTheme from './themes/purpleTheme'
import greenTheme from './themes/greenTheme'
import AppLocale from '../lngProvider'
import { setDarkTheme, setThemeColor } from '../store/ducks/Setting'
import AppLayout from './AppLayout'
import AuthRoute from './AuthRoute'
import LoadingPage from './LoadingPage'

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [hasToken, setHasToken] = useState(false)
  const { data, loading } = useSelector(({ auth }) => auth)

  const storageTypeUser = getTypeUser()

  useEffect(() => {
    const accessToken = getToken()
    const refreshToken = getRefreshToken()

    if (
      !loading &&
      (!data || (data?.roles?.toString() !== storageTypeUser && refreshToken))
    ) {
      dispatch(AuthActions.login({ refresh_token: refreshToken }))
    }

    if (!loading) {
      if (!accessToken) {
        if (!refreshToken) {
          history.push('/signin')
        } else {
          dispatch(AuthActions.login({ refresh_token: refreshToken }))
        }
      } else {
        setTimeout(() => {
          setHasToken(true)
        }, 1000)
      }
    }
  }, [data, loading])

  if (hasToken) {
    return <Route {...rest} render={props => <Component {...props} />} />
  }

  return <LoadingPage />
}

const App = ({ location, history }) => {
  const dispatch = useDispatch()
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(
    ({ settings }) => settings,
  )
  const { initURL, data, success } = useSelector(({ auth }) => auth)
  const typeUser = getTypeUser()

  const isDarkTheme = darkTheme

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
    if (initURL === '') {
      console.info('INIT URL', initURL, history.location.pathname)
      dispatch(AuthActions.setInitUrl(history.location.pathname))
    }
    const params = new URLSearchParams(location.search)
    if (params.has('theme-name')) {
      dispatch(setThemeColor(params.get('theme-name')))
    }
    if (params.has('dark-theme')) {
      dispatch(setDarkTheme())
    }
  }, [dispatch, initURL, history.location.pathname, location.search])

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme)
        break
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme)
        break
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme)
        break
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme)
        break
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme)
        break
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme)
        break
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme)
        break
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme)
        break
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: 'rtl' })
        break
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme)
        break
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme)
        break
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme)
        break
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme)
        break
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme)
        break
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme)
        break
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme)
        break
      }
      default:
        createMuiTheme(indigoTheme)
    }
    return applyTheme
  }

  let applyTheme = createMuiTheme(indigoTheme)
  if (isDarkTheme) {
    document.body.classList.add('dark-theme')
    applyTheme = createMuiTheme(darkTheme)
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme)
  }

  if (isDirectionRTL) {
    applyTheme.direction = 'rtl'
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl')
    applyTheme.direction = 'ltr'
  }

  const currentAppLocale = AppLocale[locale.locale]

  useEffect(() => {
    if (success && location.pathname === '/signin') {
      dispatch(AuthActions.loginReset())

      if (typeUser === 'root' || typeUser === 'admin') {
        history.push('/dashboard')
      } else {
        history.push('/sites')
      }
    }
  }, [success])

  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <RTL>
            <div className="app-main">
              <Switch>
                {data && <RestrictedRoute component={AppLayout} />}
                <AuthRoute />
              </Switch>
              <NotificationContainer />
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
