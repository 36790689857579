import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getToken,
  getTypeUser,
  getRefreshToken,
  removeTokens,
} from 'util/index';
import { Creators as AuthActions } from 'store/ducks/Auth';
import SignIn from './SignIn';

function AuthRoute({ location }) {
  const dispatch = useDispatch();
  const typeUser = getTypeUser();
  const { data, loading } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    const accessToken = getToken();
    const refreshToken = getRefreshToken();
    const storageTypeUser = getTypeUser();

    if (refreshToken) {
      // removeTokens();

      if (
        !loading &&
        (!data || (data?.roles?.toString() !== storageTypeUser && refreshToken))
      ) {
        dispatch(AuthActions.login({ refresh_token: refreshToken }));
      }

      if (!loading) {
        if (!accessToken) {
          if (!refreshToken) {
            history.push('/signin');
          } else {
            dispatch(AuthActions.login({ refresh_token: refreshToken }));
          }
        }
      }
    }
  }, [data, loading]);

  if (!typeUser && !data && location.pathname !== '/signin') {
    return <Redirect to="/signin" />;
  }

  return (
    <Switch>
      <Route path="/signin" component={SignIn} />
    </Switch>
  );
}

export default AuthRoute;
